module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Noonan's Sports Bar & Grill","short_name":"Noonan's Sports Bar & Grill","start_url":"/","background_color":"#df1c21","theme_color":"#df1c21","display":"standalone","icon":"./src/favicon.png","icons":[{"src":"./src/favicon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d4a8b372e2bcbfff4471c15374f41a12"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":10}},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":10}}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-SBX8D7XTKK","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.noonanssportsbar.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WLW6PPT","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    }]
